<template>
    <div class="main-limiter margin-bottom-5">
        <article class="main">
          <h1>Explainer Dashboard,<br />a tool to answer how the machine learning models works</h1>
          <DoroImage :src="ImageMain" max-width="700px" :square="true"></DoroImage>

          <p>Recently at work, I was building a very interesting model for catching investment frauds. We were very keen to make sure that our business partner understood why the model made this decision and not another. After all, the model is supposed to support our team to catch criminals! It is crucial that domain experts understand how estimators work and, in case of errors, they let us know to correct the developed model.
          </p>
          <p>
            So far I have mainly used the Shap library for explaining a Machine Learning model. This time I found an interesting alternative, which is called Explainer Dashboard!
          </p>
          <p>
            In this post, I'll just walk you through the Explainer Dashboard package written by Oege Dijk and show you what it can do to better understand how the model works under the hood.
          </p>

          <h2>What is Explainer Dashboard?</h2>
          <p>
            It's a Python package that makes it easy to quickly deploy an interactive dashboard (in the form of a web application) to help in making a machine learning model work and output explainable. It provides interactive charts on model performance, the power of features, the contribution of a feature to individual predictions, "what if" analyses, or visualizations of individual trees!
          </p>
          <p>
            Explainer Dashboard customizes the metrics and charts according to the problem the model solves (classification / regression).
          </p>

          <DoroImage :src="ImageDashboard" max-width="511px"></DoroImage>

          <p>
            Importantly - the library is being constantly developed and more functionalities are being added. Its current main drawback is that it does not yet support deep learning models.
          </p>

          <DoroImage :src="Image11" max-width="683px" :square="true"></DoroImage>

          <p>
            Project’s GitHub: <URL to="https://github.com/oegedijk/explainerdashboard">https://github.com/oegedijk/explainerdashboard</URL>
          </p>
          <p>
            An example to tinker around on herokuapp: <URL to="https://titanicexplainer.herokuapp.com/">https://titanicexplainer.herokuapp.com/</URL>
          </p>
          <p>
            Okay, based on Titanic's immortal dataset, let's go through specific metrics, features, and graphs.
          </p>

          <h2>Loading the Explainer Dashboard library</h2>

          <p>
            You don't need to know AI dashboard building in dash and charting in plotly. The entry threshold to put up your own dashboard is minimal. All you need to do is install the library:
          </p>

          <code-highlight language="bash">
            <pre>conda install -c conda-forge explainerdashboard</pre>
          </code-highlight>

          <p>
            and you can already enjoy loading packages:
          </p>

          <code-highlight language="javascript">
            <pre>from explainerdashboard import ClassifierExplainer, ExplainerDashboard
from explainerdashboard.datasets import titanic_survive, titanic_names

import xgboost as xgb</pre>
          </code-highlight>

          <p>
            We will build a simple model using one of my favorite XGBoost model:
          </p>

          <code-highlight language="javascript">
            <pre>X_train, y_train, X_test, y_test = titanic_survive()
train_names, test_names = titanic_names()
model = xgb.XGBClassifier(n_estimators=50, max_depth=5)
model.fit(X_train, y_train)</pre>
          </code-highlight>

          <p>
            Note that our goal is not to build the best model, but to understand how it works. That's why I'm building without the fun of selecting optimal features and optimizing hyperparameters.
          </p>

          <p>
            Now let's add an even nicer description of the characteristics:
          </p>

          <code-highlight language="javascript">
            <pre>feature_descriptions = {
    "Sex": "Gender of passenger",
    "Gender": "Gender of passenger",
    "Deck": "The deck the passenger had their cabin on",
    "PassengerClass": "The class of the ticket: 1st, 2nd or 3rd class",
    "Fare": "The amount of money people paid",
    "Embarked": "the port where the passenger boarded the Titanic. Either Southampton, Cherbourg or Queenstown",
    "Age": "Age of the passenger",
    "No_of_siblings_plus_spouses_on_board": "The sum of the number of siblings plus the number of spouses on board",
    "No_of_parents_plus_children_on_board" : "The sum of the number of parents plus the number of children on board",
}</pre>
          </code-highlight>

          <p>
            and we can launch the explainer dashboard:
          </p>

          <code-highlight language="javascript">
            <pre>explainer = ClassifierExplainer(
  model, X_test, y_test,
  cats=['Deck', 'Embarked',
    {'Gender': ['Sex_male', 'Sex_female', 'Sex_nan']}],
  cats_notencoded={'Embarked': 'Stowaway'}, # defaults to 'NOT_ENCODED'
  descriptions=feature_descriptions, # adds a table and hover labels to dashboard
  labels=['Not survived', 'Survived'], # defaults to ['0', '1', etc]
  idxs = test_names, # defaults to X.index
  index_name = "Passenger", # defaults to X.index.name
  target = "Survival", # defaults to y.name
)

db = ExplainerDashboard(explainer,
  title="Titanic Explainer", # defaults to "Model Explainer"
  shap_interaction=True, # you can switch off tabs with bools
)
db.run(port=2021)</pre>
          </code-highlight>

          <DoroImage :src="ImageC1" max-width="683px" :square="true"></DoroImage>

          <p>
            Congratulations. Explainer Dashboard set up. Now we can move on to understanding how our model works.
          </p>

          <h2>Feature Importance</h2>

          <p>
            Here we will find the answer to the question "which characteristics have the greatest impact on the power of the model?". In the report, we will find information on two metrics: the Permutation Importance and the Shapley Values!
          </p>

          <h3>Permutation Importance</h3>

          <p>
            The idea is this: the importance of a feature can be measured by looking at how much the score of a metric of interest (e.g. F1) decreases when we get rid of a particular feature. For such a check, one can remove the feature from the dataset, re-train the estimator and check the result. However, this requires a lot of time, as it involves recalculating the model after removing each feature. And it will be a different model than the one we currently have, so such a test will not answer us 100% whether it will be the same as the initial model.
          </p>

          <p>
            Fortunately, someone clever came up with the idea that instead of removing a feature, we can replace it with random noise - the column continues to exist, but no longer contains useful information. This method works if the noise is taken from the same distribution as the original feature values. The easiest way to obtain such noise is to reshuffle the feature values.
          </p>

          <p>
            In summary, Permutation Importance is a technique for checking features that can be applied to any fitted estimator if the data is tabular. It is fast and useful for nonlinear and opaque estimators.
          </p>

          <p>
            Permutation importance of a feature is defined as the decrease in the model score when the value of a single feature is randomly reshuffled. This procedure breaks the link between the model’s input and the target, so a decrease in the model score indicates how much the model depends on that particular feature.
          </p>

          <p>
            A negative value says that after the feature is reshuffled, the power of the model for a given metric increases! So a score close to zero or negative means that the feature adds little to the model.
          </p>

          <DoroImage :src="Image21" max-width="683px" :square="true"></DoroImage>

          <h3>Shapley Values</h3>

          <p>
            Shapley Value is a method of assigning profit between players according to their contribution to the total game. Players cooperate with each other in a coalition and derive some profit from it. Intuitively, it can be said that the Shapley Value tells how much a given player should expect to gain from the total given what, on average, he or she contributes to the game in a given coalition.
          </p>

          <p>
            If you are interested in a detailed description of how Shapley value works and is calculated, I invite you to read the following blog article:
          </p>

          <p>
            <URL to="https://christophm.github.io/interpretable-ml-book/shapley.html">https://christophm.github.io/interpretable-ml-book/shapley.html</URL>
          </p>

          <h2>Classification Statistics</h2>

          <p>Here we can find general information about the model along with metrics and visualizations showing whether the classification model is performing reasonably.</p>

          <p>At the very top of the tab, we can set a global cut-off for the entire tab. The classification model returns the probability that a given event will occur. If this probability is below the cut-off value, it will take a value of 0, and above it will take a value of 1. For a cut-off threshold set in this way, we will have graphs and metrics calculated in this tab that need a specific class predicted, not the probability itself.</p>

          <DoroImage :src="Image31" max-width="683px" :square="true"></DoroImage>

          <p>Of course, on each chart, we will be able to change this threshold and visualize for ourselves how this would affect the policy of using a particular model.</p>

          <h3>Metrics & Confusion Matrix</h3>

          <p>At the very beginning, you will find all the well-known metrics that are used with classification models, such as:</p>
          <ul>
            <li>Accuracy,</li>
            <li>Precision,</li>
            <li>Recall,</li>
            <li>F1,</li>
            <li>ROC AUC,</li>
            <li>Precision-Recall curve,</li>
            <li>Log Loss.</li>
          </ul>

          <p>In this article, I will not elaborate on each of the metrics. If you don't associate any of them I suggest you take a look at the sklearn documentation, where it is super described: <URL to="https://scikit-learn.org/stable/modules/classes.html#module-sklearn.metrics">HERE</URL>.</p>

          <p>Additionally, you will find Confusion Matrix. Here it is possible to play with the cut-off thresholds to select the optimal level. Obviously, if the data were balanced, a value of 0.5 should be a good starting point. You can then tweak the cutoff threshold up and down to work with the business to see how the model performs and classifies customers.</p>

          <DoroImage :src="Image41" max-width="683px" :square="true"></DoroImage>


          <h3>Precision Plot</h3>

          <p>Think of it this way: we have a probability score for our model and we sort the observations in ascending order. Then we divide the observations into 7 groups (I set the parameter bin_size = 7, because it looked nice to plot).</p>

          <p>And now we draw a graph, where:</p>

          <ul>
            <li>in blue, with a column graph, we mark the number of observations in a given group,</li>
            <li>in orange with a line graph % of people in a given group with actual first class (in our case, survivors of the Titanic escape),</li>
            <li>with a green line graph % of people in the second class (unlucky people who did not escape from the Titanic).</li>
          </ul>

          <p>Ta-dah:</p>

          <DoroImage :src="Image51" max-width="683px" :square="true"></DoroImage>

          <p>In the above graph, I have reached the expected shape of the orange and green lines. As the value of the probability returned by the model (X-axis) increases, the number of survivors increases (orange line), and the number of non-survivors decreases (green line). If the lines were flat all the way through (still around 50%) or intersected every now and then (once higher the orange line and once higher the green line), it would mean that the model can't differentiate customers properly and doesn't work correctly.</p>

          <p>Note that the graph strongly depends on your chosen division method and how many parts you divide it into. See what the shape of the graph looks like for the same model when the number of compartments is significantly increased:</p>

          <DoroImage :src="Image61" max-width="683px" :square="true"></DoroImage>

          <p>Here you can simply observe that the model distinguishes survivors very well - the orange line shows 100%, which means that in these groups the model did not get it wrong at all. On the other hand, you can see that in the middle between the value of 0.25 and 0.60 the model works middling.</p>

          <p>Do not think that something is wrong with the model, only then take a look at the blue bar. In this case, see that there are 10 people in each group. With such a number of people, 1 person is 10%. Hence, there is nothing to be surprised by such fluctuations and simply aggregate the data more.</p>

          <h3>ROC AUC & PR AUC</h3>

          <p>
            AUC (Area Under Curve) stands for the area under the curve. So, in order to talk about the ROC AUC or PR AUC score, we must first define what ROC and PR are.
          </p>
          <p>
            In a nutshell, an ROC curve is a graph that visualizes the trade-off between a True Positive Rate (Recall)(True Positive Rate) and a False Positive Rate (fall-out) (False Positive Rate).
          </p>
          <p>
            It can be interpreted to mean that this metric shows how good the model's prediction ranking is. It tells how likely it is that a randomly selected positive instance has a higher ranking than a randomly selected negative instance.
          </p>
          <p>
            PR, on the other hand (Precision-Recall) is a curve that combines Pprecision (precision / PPV) and Rrecall (recall /TPR) in a single visualization. For each threshold, you calculate Precision and Recall and plot them. The higher the curve on the Y axis, the better the performance of the model. Of course, the higher the Rrecall value, the lower the Pprecision. It's worth considering the cut-off point when the OY-axis graph (i.e., Precision) begins to rapidly decline toward a value of 0.
          </p>
          <p>
            For this reason, if you care more about the positive class, a better choice is to use PR AUC, which is more sensitive to improvements in the positive class.
          </p>
          <p>
            The second important thing is that the ROC AUC can distort the picture if the data are heavily unbalanced. This is because the false-positive rate of highly unbalanced data sets is reduced due to a large number of true-negative results.
          </p>

          <DoroImage :src="Image71" max-width="683px" :square="true"></DoroImage>

          <h3>Classification Plot</h3>

          <p>Here you will find a summary of how the population looks below and above the cut-off.</p>

          <p>If you grab the slider and move it left and right, you'll see a cool visualization of how people jump between classes. This will help you explain how the cut-off level will affect the business when it starts using the model and help you choose a parameter.</p>

          <DoroImage :src="Image81" max-width="683px" :square="true"></DoroImage>

          <h3>Cumulative Precision</h3>

          <p>In this graph, we see the percentage of each class if we select the top X % of customers. In the example below, for a cut-off equal to 0.59, you can see that below this value is 22.5% of the population, with survivors accounting for just over 91% and deaths less than 9%.</p>

          <DoroImage :src="Image91" max-width="683px" :square="true"></DoroImage>

          <h3>Lift Curve</h3>

          <p>This is a graph that answers the question of how many times it is better than if we had randomly sampled observations.</p>

          <p>In the example below, you can see that for a cut-off = 0.59 lift is 2.55. Our model for this cutoff indicates a positive class for 93.0%, while a random model at this cut-off would indicate 36.5% (93%/2.5).</p>

          <DoroImage :src="Image101" max-width="683px" :square="true"></DoroImage>



          <h2>Individual Prediction</h2>

          <p>This is where the magic happens for the business audience. Because as we sit down with the domain experts we can disenchant them with each specific case, why the model made the decision it did and not another, and what influenced it and to what extent.</p>

          <p>At the top of the tab, we can select a specific observation (e.g. first female passenger).</p>

          <p>Let's see what probability the model returned for her. In this case, you can see that Ms. Florence Briggs has a probability of survival of 90.6%.</p>


          <DoroImage :src="Image111" max-width="683px" :square="true"></DoroImage>

          <p>Let's see why.</p>


          <h3>Partial Dependence Plot (PDP).</h3>

          <p>
            A partial dependence plot (PDP) shows the marginal effect of a feature on the predicted outcome of a machine learning model. Partial dependence works by marginalizing the output of the machine learning model over the distribution of features from the set so that the function shows the relationship between the features of interest from the set and the predicted output.
          </p>

          <p>
            If the feature for which you have calculated PDPs is not correlated with other features, the PDPs perfectly represent how the feature affects the average prediction. In the uncorrelated case, the interpretation is clear: a Partial Dependence Plot shows how the average prediction in your dataset changes when the j-th feature is changed.
          </p>

          <p>
            However, it gets more complicated when features are correlated. We may accidentally create new data points in areas of the feature distribution where the actual probability is very low and this can distort the picture a bit. For instance, it's highly unlikely that someone is 2 meters tall, but weighs less than 50 kg - for such feature combination PDP values may have very high uncertainty.
          </p>

          <p>
            Let's look at our example. We select the gender feature and see that for our case (blue bold line) if we changed the gender from female to male, the probability of survival would drop from 90.6% to ... 40%. Also in the graph, we have shown the average value for the sample - the bold gray line.
          </p>

          <DoroImage :src="Image121" max-width="683px" :square="true"></DoroImage>

          <p>
            You can look at each attribute and better understand the performance of the model on the whole. Below, age - you can tell from the graph that it didn't matter that much (in the currently tested model!).
          </p>

          <DoroImage :src="Image131" max-width="683px" :square="true"></DoroImage>

          <h3>Contributions Plot</h3>

          <p>The Contributions Plot works on a similar principle as the PDP except that it shows the contribution of each variable individually and what effect it had on the final outcome. Its main advantage over PDP is that we can plot the impact of more than 2 features on prediction for a particular observation.</p>

          <p>You can see that in our case, the characteristic that contributes the most is gender, and the second most for that person is the price they paid for the ticket.</p>

          <p>In addition, the colors indicate what worked to increase the probability of survival, and in red to decrease the probability of survival.</p>

          <DoroImage :src="Image141" max-width="683px" :square="true"></DoroImage>

          <p>You have all the results collected below in a table:</p>

          <DoroImage :src="Image151" max-width="683px" :square="true"></DoroImage>


          <h2>What if</h2>

          <p>In my opinion, this is the most interesting section. It shows the same thing we saw in the individual evaluation tab with the difference that we can modify all the features at will and see what effect it had on the model.</p>

          <p>If you sit down with domain experts at this tab they can verify that the model works intuitively and can give interesting input regarding where they think the model is not quite behaving correctly. It is a common technique for discovering a model's fairness and stability.</p>

          <DoroImage :src="Image161" max-width="683px" :square="true"></DoroImage>



          <h2>Feature Dependence</h2>

          <p>Here we have two graphs that will show us how the values of given characteristics affect the increase or decrease in probability based on Shapley Values.</p>

          <p>
            More about Shap itself <URL to="https://shap.readthedocs.io/en/latest/index.html">HERE</URL>.
          </p>

          <h3>Shap Summary</h3>

          <p>Here, in the basic version, we get the Shap chart for the features we already know from the first tab. However, it differs in that we can view it in detail, and then it draws us observations. We can select a particular observation and see where it is in the background of the whole.
          </p>

          <p>
            In addition, from the graphs, we can see which characteristics increase the probability of survival (those to the right of 0 on the OX axis). Conveniently, we have a  colored scale, where blue corresponds to smaller values and red - larger ones).
          </p>

          <DoroImage :src="Image171" max-width="683px" :square="true"></DoroImage>

          <h3>Shap Dependence</h3>

          <p>
            Here we can take a closer look at a particular feature of how the distribution looks in terms of Shapley Values.
          </p>

          <p>
            Below you can see how strongly gender in the case of the Titanic data affects survival rates:
          </p>

          <DoroImage :src="Image181" max-width="683px" :square="true"></DoroImage>

          <p>
            An interesting option is that the graph is selected according to the types of data. In the case of the continuous data "Fare", a dot plot is presented instead of a violin plot.
          </p>

          <p>
            Note that you can add an additional color based on another feature to see the other variable.
          </p>

          <p>
            Below, at first glance, you can't see the dependence of ticket price ("Fare") on gender - the blue and orange colors are mixed. On the other hand, it is immediately transparent that the "Fare" variable itself has an impact on the positive probability of survival - the higher the "Fare" value, the more characteristics have a positive Shapley Value.
          </p>

          <DoroImage :src="Image191" max-width="683px" :square="true"></DoroImage>

          <h2>Decision Trees</h2>

          <p>
            In this tab, we have visualized how successive trees counted and we can visualize a specific tree. This allows us to closely examine the "guts" of our ensemble model.
          </p>

          <p>
            It's worth noting that depending on the model we have, these can be a tad different graphs.
          </p>

          <p>
            Below I have shown two examples:
          </p>

          <p>
            (a) for the Random Forest, where we have shown each of the 50 trees that gave a probability for a given observation:
          </p>

          <DoroImage :src="Image201" max-width="683px" :square="true"></DoroImage>

          <p>
            (b) for XGBoost, where each successive tree is shown:
          </p>

          <DoroImage :src="Image211" max-width="683px" :square="true"></DoroImage>

          <p>
            In addition, for tree algorithms, we can generate a specific tree as it looked:
          </p>

          <DoroImage :src="Image221" max-width="683px" :square="true"></DoroImage>

          <p>Note: In my case, in order for me to display the tree I had to install the dtreeviz package as described in the installation instructions: <URL to="https://github.com/parrt/dtreeviz">https://github.com/parrt/dtreeviz</URL>.
          </p>

          <h2>Regression Statistics</h2>

          <p>
            Here you will find general information about the regression model, along with metrics and visualizations showing whether the regression model is working reasonably. This tab is present if our model predicts a continuous value, such as product price, growth, or income.
          </p>

          <p>
            At the very beginning, you will find any metrics you are familiar with that are used with regression models, such as:
          </p>

          <ul>
            <li>Root Mean Squared Error,</li>
            <li>Mean Average Error,</li>
            <li>R2 Coefficient.</li>
          </ul>

          <p>
            Also at a glance, instead of the Confusion Matrix, we have a "Predicted vs Actual" chart. It immediately illustrates how strongly the variable predicted by the model differs from the actual value.
          </p>

          <DoroImage :src="Image231" max-width="683px" :square="true"></DoroImage>

          <p>In our case, we can see that the model predicting the ticket price ("Fare") did much better at predicting low amounts. The blue points depicting the actual values are closer to the predicted value (orange line).</p>

          <p>In addition, you can simply choose a logarithmic scale on the chart.</p>

          <h3>Residuals chart</h3>

          <p>Another graph showing how and where the model is wrong is the residuals chart.</p>

          <p>Residuals are the differences between the actual value and the value predicted by the model. Immediately from the chart, you will see on which values the model underestimates the predicted ticket price and on which it overestimates.</p>

          <DoroImage :src="Image241" max-width="683px" :square="true"></DoroImage>

          <h3>Plot vs. feature</h3>

          <p>
            With this chart, you can get a better understanding of what the distribution looks like for the selected feature:
          </p>

          <ul>
            <li>predicted values,</li>
            <li>actual values,</li>
            <li>residuals,</li>
            <li>the ratio of residuals,</li>
            <li>the logarithm of the residuals.</li>
          </ul>

          <p>
            It is worth noting that, depending on the distribution of the selected features, the graph will take different forms: a scatter plot for discrete or a violin plot for continuous.
          </p>

          <DoroImage :src="Image251" max-width="683px" :square="true"></DoroImage>

          <h2>Summary of the Explainer Dashboard</h2>

          <p>You can adapt the Explainer Dashboard to suit you, i.e. fire only the appropriate tabs, expose applications in a new window, or launch in Jupiter Notebook.</p>

          <p>We hope you will be able to use this package to present the results of your work.</p>

          <br />

          <p style="font-size:0.8em">
            <i>This article is based on an article by Mirosław Mamczur published on his blog: <URL to="https://miroslawmamczur.pl/explainer-dashboard/">miroslawmamczur.pl</URL></i> <br />
            <i>Translation and publication on airev.us made with the consent of the author.</i> <br />
          </p>
        </article>
    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';
    import DoroImage from '@/components/DoroImage';
    import URL from '@/components/URL';
    import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
    import "vue-code-highlight/themes/prism-coy.css";

	import ImageMain from '@/assets/articles/explainer-dashboard/explainer-dashboard.jpg';
  import ImageDashboard from '@/assets/articles/explainer-dashboard/explainerdashboard_small.gif';
  import Image11 from '@/assets/articles/explainer-dashboard/1-1.png';
  import Image21 from '@/assets/articles/explainer-dashboard/2-1.png';
  import Image31 from '@/assets/articles/explainer-dashboard/3-1.png';
  import Image41 from '@/assets/articles/explainer-dashboard/4-1.jpg';
  import Image51 from '@/assets/articles/explainer-dashboard/5-1.png';
  import Image61 from '@/assets/articles/explainer-dashboard/6-1.png';
  import Image71 from '@/assets/articles/explainer-dashboard/7-1.png';
  import Image81 from '@/assets/articles/explainer-dashboard/8-1.png';
  import Image91 from '@/assets/articles/explainer-dashboard/9-1.png';
  import Image101 from '@/assets/articles/explainer-dashboard/10-1.png';
  import Image111 from '@/assets/articles/explainer-dashboard/11-1.png';
  import Image121 from '@/assets/articles/explainer-dashboard/12-1.png';
  import Image131 from '@/assets/articles/explainer-dashboard/13-1.png';
  import Image141 from '@/assets/articles/explainer-dashboard/14-1.png';
  import Image151 from '@/assets/articles/explainer-dashboard/15-1.png';
  import Image161 from '@/assets/articles/explainer-dashboard/16-1.png';
  import Image171 from '@/assets/articles/explainer-dashboard/17-1.png';
  import Image181 from '@/assets/articles/explainer-dashboard/18-1.png';
  import Image191 from '@/assets/articles/explainer-dashboard/19-1.png';
  import Image201 from '@/assets/articles/explainer-dashboard/20-1.png';
  import Image211 from '@/assets/articles/explainer-dashboard/21-1.png';
  import Image221 from '@/assets/articles/explainer-dashboard/22-1.png';
  import Image231 from '@/assets/articles/explainer-dashboard/23-1.png';
  import Image241 from '@/assets/articles/explainer-dashboard/24-1.png';
  import Image251 from '@/assets/articles/explainer-dashboard/25-1.png';
  import ImageC1 from '@/assets/articles/explainer-dashboard/c1.png';
  import ImageTitle from '@/assets/articles/explainer-dashboard/title.jpg';

	@Component({
        components: {
            DoroImage,
            URL,
            CodeHighlight,
        },
        metaInfo() {
            return {
              title: 'Explainer Dashboard, a tool to answer how the machine learning models works',
              meta: [
                {
                  name: 'title',
                  content: "Explainer Dashboard, a tool to answer how the machine learning models works"
                },
                {
                  name: 'description',
                  content: "Explainer Dashboard is a Python package that makes it easy to quickly interpret a machine learning model. Check out how it works in our article"
                },
                {
                  property: 'og:title',
                  content: "Explainer Dashboard, a tool to answer how the machine learning models works",
                },
                {
                  property: 'og:image',
                  content: "https://airev.us" + ImageTitle,
                },
                {
                  property: 'og:description',
                  content: "Explainer Dashboard is a Python package that makes it easy to quickly interpret a machine learning model. Check out how it works in our article",
                },
                {
                  property: 'og:url',
                  content: "https://airev.us/explainer-dashboard",
                },
                {
                  property: 'twitter:card',
                  content: 'summary_large_image',
                },
                {
                  property: 'twitter:url',
                  content: "https://airev.us/explainer-dashboard",
                },
                {
                  property: 'twitter:title',
                  content: "Explainer Dashboard, a tool to answer how the machine learning models works",
                },
                {
                  property: 'twitter:description',
                  content: "Explainer Dashboard is a Python package that makes it easy to quickly interpret a machine learning model. Check out how it works in our article",
                },
                {
                  property: 'twitter:image',
                  content: "https://airev.us" + ImageTitle,
                }
              ],
              script: [{
                type: 'application/ld+json',
                json: {
                  "@context": "https://schema.org",
                  "@type": "Article",
                  "name": "Explainer Dashboard, a tool to answer how the machine learning models works",
                  "description": "Explainer Dashboard is a Python package that makes it easy to quickly interpret a machine learning model. Check out how it works in our article",
                  "image": "https://airev.us" + ImageTitle,
                }
              }]
            }
        }
    })
    export default class ExplainerDashboard extends Vue {
		get ImageMain() { return ImageMain; }
    get ImageDashboard() { return ImageDashboard; }
    get Image11() { return Image11; }
    get Image21() { return Image21; }
    get Image31() { return Image31; }
    get Image41() { return Image41; }
    get Image51() { return Image51; }
    get Image61() { return Image61; }
    get Image71() { return Image71; }
    get Image81() { return Image81; }
    get Image91() { return Image91; }
    get Image101() { return Image101; }
    get Image111() { return Image111; }
    get Image121() { return Image121; }
    get Image131() { return Image131; }
    get Image141() { return Image141; }
    get Image151() { return Image151; }
    get Image161() { return Image161; }
    get Image171() { return Image171; }
    get Image181() { return Image181; }
    get Image191() { return Image191; }
    get Image201() { return Image201; }
    get Image211() { return Image211; }
    get Image221() { return Image221; }
    get Image231() { return Image231; }
    get Image241() { return Image241; }
    get Image251() { return Image251; }
    get ImageC1() { return ImageC1; }
    get ImageTitle() { return ImageTitle; }

    }
</script>
